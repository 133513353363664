<template>
  <span>
    {{ price }}
  </span>
</template>

<script lang="ts" setup>
const {locale} = useI18n();

const props = defineProps<{
  value: number,
}>();

const formatter = computed(() => new Intl.NumberFormat(locale.value, {
  style: 'currency',
  currency: 'EUR',
}));

const price = computed(() => formatter.value.format(props.value));
</script>
